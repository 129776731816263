<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <form method="post" @submit.prevent="onSubmit('frmGoal')" data-vv-scope="frmGoal" novalidate="novalidate">
    <div class="row justify-content-md-center">
      <div class="col-12 col-md-12">
        <div class="card card-fullheight">
          <div class="card-body">
            <h5 class="box-title mb-3">{{ $t('sidebar.goals') }}</h5>
            <div class="row">
                <custom-input
                  v-model="goal.year"
                  name="goal.year"
                  :label="this.$i18n.t('form.goal.year')"
                  type="text"
                  rootClassName="col-sm-2 form-group mb-4"
                  :max-length="25"
                  v-validate="{ required: true }"
                  :error="submitted = errors.first('goal.year')">
                </custom-input>
                <div class="col-sm-4 form-group mb-4">
                  <label>{{ this.$i18n.t('form.goal.idFrequency.label') }}</label>
                  <v-select :disabled="isEdit" name="name" label="name" :clearable="false" :searchable="true" v-model="goal.idFrequency" @search="frequencyFetchOptions" :options="frequencyList"
                             @input="onSelectFrequency"  >
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.name }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.name }}
                      </div>
                    </template>
                  </v-select>
                </div>
              </div>
              <div class="row">
                <div  class="col-12" >
                  <div class="row">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <td>Indicador</td>
                          <td style="text-align: right;" v-for="(periodIndex) in (12 / goal.idFrequency.intervals)" :key="periodIndex">
                            {{ $t('form.goal.idFrequency.' + getFrequencyLabel + '.' + periodIndex) }}
                          </td>
                          <td style="text-align: right;" v-if="getFrequencyLabel !==  'yearly'">Total (Ano)</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(indicator, indicatorIndex) in goal.goals" :key="indicatorIndex" v-show="indicator.show">
                          <td>
                            {{ indicator.type.name }}
                          </td>
                          <td v-for="(goal, goalIndex) in indicator.frequency_goal" class="editable" :key="goalIndex" >
                            <custom-input
                              v-model="goal.value"
                              name="goal.value"
                              type="text"
                              rootClassName="form-group no-margin"
                              inputClassName="md-form-control no-margin"
                              :v-mask="{ 'allowPlus':true, 'alias': 'currency', 'prefix': '', 'groupSeparator': '.', 'radixPoint':','}"
                              :error="submitted && errors.first('goal.value')"
                              :indicator = "indicator"
                              >
                            </custom-input>
                          </td>
                          <td class="text-right align-middle" v-if="getFrequencyLabel !==  'yearly'">
                            {{ (calculateTotal(indicator)) }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>

      <!-- Submit -->
      <div class="col-12">
        <button type="button" class="btn btn btn-outline-light pull-right" @click="onSave">
          Salvar
        </button>
        <button type="button" class="btn btn-link btn-sm mt-1 pull-right" @click="onCancelGoal">
          Cancelar
        </button>
      </div>
    </div>
    </form>
  </div>
</template>

<script>
// Components

// eslint-disable-next-line
import { util } from '@/utils/utils.js'

// Form
import CustomInput from '@/components/Forms/CustomInput.vue'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// eslint-disable-next-line
import '@/assets/vendor/reflow-table/js/reflow-table.js'
import '@/assets/vendor/reflow-table/css/reflow-table.css'

// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

// vue-select
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

// Services
import GoalService from '@/services/GoalService'
import GoalBonusService from '@/services/GoalBonusService'

export default {
  name: 'GoalForm',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('basic.goal-bonus.title') + ' - %s'
    }
  },
  data () {
    return {
      percentage: 10,
      isEdit: false,
      formErrors: {},
      isLoading: true,
      fullPage: true,
      // List of options to select on select input
      goalList: [],
      frequencyList: [],
      //  Selected Values (Form)
      goal: {
        year: (new Date()).getFullYear(),
        value: null,
        idFrequency: {
          id: 2,
          description: 'Metas controladas trimestralmente.',
          intervals: 3,
          name: 'Trimestral'
        },
        goals: [
          {
            frequency_goal: [
              {
                percent: 0,
                period: 1,
                value: 0
              },
              {
                percent: 0,
                period: 2,
                value: 0
              },
              {
                percent: 0,
                period: 3,
                value: 0
              },
              {
                percent: 0,
                period: 4,
                value: 0
              }
            ],
            show: true,
            type: {
              description: null,
              id: 38,
              is_percent: null,
              name: 'Lucro Líquido',
              sort_order: 1
            }
          }
        ]
      },
      submitted: false

    }
  },
  components: {
    Loading,
    vSelect,
    CustomInput
  },
  // Apply filters to local filter
  beforeMount () {

  },
  mounted () {
    // this.$store.dispatch('SetShowFilter', true)
    // this.$store.dispatch('setShowSearch', false)
  },
  created () {
    let _this = this
    this.isEdit = this.$route.name === 'GoalEdit'

    // Load basilares data
    GoalService.getFrequency().then(res => {
      _this.frequencyList = res.data.data

      // If is edit populate values
      let year = this.$route.params.year
      if (year) {
        GoalBonusService.getGoalByYear(year).then(goals => {
          _this.goal = goals.data.data
        }).catch(() => {
          _this.isLoading = false
        })
      }
      // End edit
      _this.isLoading = false
    })
  },
  computed: {
    getFrequencyLabel () {
      let intervals = this.goal.idFrequency.intervals
      let type = ''

      if (intervals === 3) {
        type = 'quarter'
      } else if (intervals === 1) {
        type = 'monthly'
      } else if (intervals === 12) {
        type = 'yearly'
      }

      return type
    }
  },
  methods: {
    calculateTotal (indicator) {
      let goals = indicator.frequency_goal

      let total = 0
      let countItems = 0
      for (let i = 0; i < goals.length; i++) {
        countItems++

        let value = goals[i].value

        if (value.toString().indexOf('.') !== -1 && value.toString().indexOf(',') !== -1) {
          value = value.toString().replaceAll('.', '')
          value = value.toString().replace(',', '.')
        } else {
          value = value.toString().replace(',', '.')
        }

        total = total + parseFloat(value)
      }

      if (indicator.type.is_percent) {
        total = total / countItems
      }

      return this.showNumber(total)
    },
    // updateValue (indicator, goals) {
    //   let values = indicator.frequency_goal
    //   let name = indicator.type.name.split(' ')
    //   let porcentagem = parseFloat(this.percentage) * 0.01 + 1
    //
    //   for (let i = 0; i < goals.length; i++) {
    //     if (goals[i].type.name.includes(name[0]) && !goals[i].type.name.includes(name[1]) && name[1] === '(Embarcados)') {
    //       let target = goals[i].frequency_goal
    //       for (let x = 0; x < values.length; x++) {
    //         if (values[x].value !== 0) {
    //           let temp = values[x].value.replace('.', '')
    //           temp = temp.toString().replace(',', '.')
    //           temp = (parseFloat(temp) * porcentagem).toFixed(2)
    //           if (temp % 1 !== 0) {
    //             target[x].value = temp.toString().replace('.', ',')
    //           } else {
    //             target[x].value = parseInt(temp)
    //           }
    //         }
    //       }
    //     }
    //   }
    // },
    displayErrors (errors) {
      let formatedErrors = util.getFormErrors(errors)
      this.formErrors = formatedErrors
    },
    showNumber (number) {
      return util.numberFormat(number, 2, ',', '.')
    },
    onSelectFrequency (val) {
      let sections = 12 / val.intervals
      for (let goalIndex in this.goal.goals) {
        // Remove previous settings
        this.goal.goals[goalIndex].frequency_goal = []

        for (let section = 1; section <= sections; section++) {
          let frequencyGoalObject = {}
          frequencyGoalObject.period = section
          frequencyGoalObject.value = 0.0
          frequencyGoalObject.percent = 0.0

          this.goal.goals[goalIndex].frequency_goal.push(frequencyGoalObject)
        }
      }
      this.goal.idFrequency = val
    },
    mountLink (itemName, object) {
      return this.$router.resolve({ name: itemName, params: { id_goal: object.id } }).href
    },
    /**
     * Triggered when the frequency search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    frequencyFetchOptions (search, loading) {
      let me = this
      loading(true)
      let filters = {
        'searchTerm': search
      }

      GoalBonusService.getFrequency(filters).then(res => {
        me.frequencyList = res.data.data
        loading(false)
      })
    },
    onCancelGoal (e) {
      var _this = this
      _this.$router.push({ name: 'GoalBonusIndex' })
    },
    onSave (e) {
      var _this = this
      this.submitted = true
      _this.$validator.validate().then(valid => {
        if (valid) {
          _this.isLoading = true

          let goalData = {
            year: _this.goal.year,
            idFrequency: _this.goal.idFrequency.id,
            goals: _this.goal.goals
          }

          if (this.$router.currentRoute.name === 'GoalBonusEdit') {
            goalData['edit'] = 'true'
          }

          GoalBonusService.getGoalByYearSave(goalData).then(response => {
            _this.$router.push({ name: 'GoalBonusIndex' })
          }).catch(err => {
            if (err.code === 400) {
              _this.displayErrors(err.errors)
            }
            _this.isLoading = false
          })
        }
      })
    }
  }
}
</script>

<style scoped>
  .swal2-popup {
    width: inherit !important;
  }

  .card-body {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .md-form-control {
    height: 30px;
  }

  .v-select.md-form-control {
    height: 35px;

  }

  td.editable {
    padding: 0;
    vertical-align: middle;
  }

  td.editable input {
    background-color: inherit;
    border: 0;
    width: 100%;
    min-height: 33px;
    box-sizing: border-box;
  }

  .card-subtitle {
    margin-top: 15px;
  }

</style>
